export const environment = {
	production: false,
	name: 'DEV',
	serverUrl: 'https://dev.api.autosmotos.es/api/v1',
	serverUrlV2: 'https://dev.api.autosmotos.es/api/v2',
	serverStorage: 'https://cdn.autosmotos.es/',
	googleMapsKey: 'AIzaSyBaYZOqfJJ2W7CWC2AO0JgXTjXuPiaCkSc',
	stripePK:
		'pk_test_51MLr62CnDPj9TaAuMcaxvISjguMWLtCakdQNLroanMtTr3Qy7HRmxRbQgCXFUF7vuctcZ9N5T4xrfwUl6trbB71Z001BQaflLR',
	esWebsite: 'https://dev.am.alaxatechtesting.lat',
	itWebsite: 'https://www.test.autosmotos.it/',
	deWebsite: 'https://www.test.autosmotos.de/',
	roWebsite: 'https://www.test.autosmotos.ro/',
	esFacebook: 'https://www.facebook.com/autosmotos.es',
	itFacebook: 'https://www.facebook.com/profile.php?id=100084640961686',
	deFacebook: 'https://www.facebook.com/people/AutosMotosde/100084510526666/',
	roFacebook: 'https://www.facebook.com/autosmotosro/',
	esEmail: 'info@autosmotos.es',
	itEmail: 'info@autosmotos.it',
	deEmail: 'info@autosmotos.de',
	roEmail: 'info@autosmotos.ro',
	googleAnalytics: {
		trackId: 'G-9R7KGBTMDH'
	},
	pusherConfig: {
		key: 'ersAkApC9WpKTLbJqEw1HqBp83C4bscS',
		cluster: 'mt1',
		wsHost: 'dev.api.autosmotos.es',
		wsPort: 6001,
		wssPort: 6001,
		forceTLS: true,
		disableStats: true
	}
};
